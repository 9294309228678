
///////////////////////////////////
// Common imports are left here and commented out for reference when creating new pages
///////////////////////////////////

// Primary Imports
//////////////////
import PropTypes from 'prop-types';
import React from 'react';
// import { useState } from 'react';
// import { useEffect } from 'react';
// import { useContext } from 'react';

// Settings
///////////
// import { PATHS } from '/src/globals';

// Common Styling
/////////////////
// import clsx from 'clsx';
// import { makeStyles } from '@material-ui/core/styles';
// import { useTheme } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import Typography from '@material-ui/core/Typography';
// import { Link } from '/src/components/Link';
// import useImageData from '/src/images/queries';
// import Img from 'gatsby-image';

// Specialty imports
////////////////////
import { TransitionFade } from '/src/components/animators';
import DemoNavigation from '/src/collections/DemoNavigation';
import PageWrapper from '../components/PageWrapper';
import { METADATA } from '../globals';

////////////////////

const propTypes = {
  theme: PropTypes.object,
};

const Landing = (props) => {

  return ( <>

    <Helmet>
      <title>{METADATA.siteTitle}</title>
      <meta name="Demo page name" content="Demo content description" />
    </Helmet>


    <PageWrapper>

      <TransitionFade>
        <Typography variant="h1">
          TBWA Gatsby Demo &#38; Template (test)
        </Typography>
        <Typography variant="body1">
          This project demonstrates a number of components usable in projects and how to set them up.
        </Typography>
        <Typography variant="body1">
          Testing IAM policy
        </Typography>
      </TransitionFade>


      <DemoNavigation/>

    </PageWrapper>

  </> )

}

Landing.propTypes = propTypes;
export default Landing;
